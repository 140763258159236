import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
// import ReactGA from 'react-ga';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Chart } from 'react-chartjs-2';
import theme from './theme';
import Router from './Router';
import { StateProvider } from './Context';
import { chartjs } from './helpers';
import ScrollToTop from './components/ScrollToTop';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
	draw: chartjs.draw,
});

// const trackingId = 'UA-29650731-2'; // Replace with your Google Analytics tracking ID
// const browserHistory = createBrowserHistory();
// ReactGA.initialize(trackingId);
// browserHistory.listen((location, action) => {
// 	ReactGA.pageview(location.pathname + location.search);
// });
// ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.set(
// 	{
// 		// userId: auth.currentUserId(),
// 		// any data that is relevant to the user session
// 		// that you would like to track with google analytics
// 	}
// );

class App extends React.Component {
	componentDidMount() {
		// window.addEventListener('load', () => {
		// 	ReactGA.initialize(trackingId);
		// });
		// ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<StateProvider>
					<BrowserRouter>
						<ScrollToTop>
							<Router />
						</ScrollToTop>
					</BrowserRouter>
				</StateProvider>
			</MuiThemeProvider>
		);
	}
}

export default App;
