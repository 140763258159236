import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  flexGrow: {
    flexGrow: 1,
  },
}));

const FlexGrow = () => {
  const classes = useStyles();
  return <div className={classes.flexGrow} />;
};

export default FlexGrow;
