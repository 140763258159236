import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LinearProgress from '@material-ui/core/LinearProgress';
// import logo from '../../logo.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	LoadingAppLogo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: 'auto',
		justifyContent: 'center',
		height: '100vh',
		minMeight: '80vh',
		fontSize: 'calc(10px + 2vmin)',
		maxWidth: '500px',
		animation: 'breathe 3.5s ease -in infinite',
		opacity: 0.5,
	},
	LinearProgress: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		width: '50%',
	},
	'@keyframes breathe': {
		'0%': {
			opacity: 1,
		},
		'25%': {
			opacity: 0.5,
		},
		'50%': {
			opacity: 0.1,
		},
		'75%': {
			opacity: 0.5,
		},
		'100%': {
			opacity: 1,
		},
	},
}));

const LoadingScreen = () => {
	const classes = useStyles();
	const loadingTextArray = [
		'Loading profile...',
		'Loading settings...',
		'Loading users...',
		'Loading clans...',
		'Loading wars...',
		'processing...',
	];

	const [ completed, setCompleted ] = React.useState(0);
	const [ round20, setRound20 ] = React.useState(0);

	React.useEffect(
		() => {
			function progress() {
				setCompleted((oldCompleted) => {
					if (oldCompleted === 100) {
						return 0;
					}
					const diff = Math.random() * 20;

					return Math.min(oldCompleted + diff, 100);
				});
			}
			const r20 = Math.ceil(completed / 20) * 20;
			setRound20(r20);

			const timer = setInterval(progress, 500);
			return () => {
				clearInterval(timer);
			};
		},
		[ loadingTextArray, completed ]
	);

	return (
		<div className={classes.root}>
			<div className={classes.LoadingAppLogo}>
				<Avatar style={{ display: 'none' }}>
					<OpenInNewIcon />
				</Avatar>
				<Typography variant="h4" gutterBottom className={classes.SectionHeader}>
					WAR-STATS.COM
				</Typography>
				<LinearProgress variant="determinate" value={completed} className={classes.LinearProgress} />
				<Typography variant="subtitle2" gutterBottom className={classes.SubTitle}>
					{loadingTextArray[round20 / 20]}
				</Typography>
			</div>
		</div>
	);
};

export default LoadingScreen;
