import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class ScrollToTop extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}

	render() {
		return this.props.children;
	}
}

ScrollToTop.propTypes = {
	children: PropTypes.element.isRequired,
};

ScrollToTop.defaultProps = {};

export default withRouter(ScrollToTop);
