const getUniqueByProperty = (arr, key) => {
	return [ ...new Set(arr.map((item) => item[key])) ];
};

const getUniqueByProperties = (arr, keys) => {
	let result;
	let key;
	let objects = [];

	return arr.filter(function(item) {
		result = false;
		key = '';
		keys.forEach(function(k) {
			key += key && key.length > 0 ? `|${item[k]}` : item[k];
		});

		if (!objects[key]) {
			objects[key] = true;
			result = true;
		}
		return result;
	}, Object.create(null));
};

export { getUniqueByProperty, getUniqueByProperties };
