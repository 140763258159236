import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  routerLink: {
    color: theme.palette.white,
    textDecoration: 'none',
    textTransform: 'uppercase',

    '&:visited': {
      textDecoration: 'none',
    },
  },
  appIcon: {
    color: theme.palette.white,
    marginBottom: theme.spacing(-1) - 1,
    marginRight: theme.spacing(1),
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/" className={classes.routerLink}>
          <SportsEsportsIcon className={classes.appIcon} /> 
          War-Stats.com
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
