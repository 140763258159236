import { getUniqueByProperties } from '../helpers';
import cwl_ofh from '../assets/data/OFH_war1.json';
import scwl_2020_20 from '../assets/data/scwl_2020-20.json';

const LoadData = async () => {
	const data = {
		users: [],
		clans: [],
		wars: [],
		cwl: [ ...cwl_ofh ],
		scwl: [ ...scwl_2020_20 ],
	};

	// Parse Clan from raw data src
	const parseClans = () => {
		let homeClans = [];
		let enemyClans = [];

		getUniqueByProperties([ ...data.cwl ], [ 'home_clan_tag' ]).forEach((item) => {
			homeClans.push({
				clanTag: item.home_clan_tag.replace('#', ''),
				clanName: item.home_clan_name,
				clanLevel: item.home_clan_level,
			});
		});
		getUniqueByProperties([ ...data.cwl ], [ 'enemy_clan_tag' ]).forEach((item) => {
			enemyClans.push({
				clanTag: item.enemy_clan_tag.replace('#', ''),
				clanName: item.enemy_clan_name,
				clanLevel: item.enemy_clan_level,
			});
		});

		return getUniqueByProperties([ ...homeClans, ...enemyClans ], [ 'clanTag' ]);
	};

	// Parse Wars from raw data src
	const parseWars = () => {
		let wars = [];
		getUniqueByProperties([ ...data.cwl ], [ 'warID' ]).forEach((item) => {
			wars.push({
				warID: item.warID,
				homeClanTag: item.home_clan_tag.replace('#', ''),
				homeClanName: item.home_clan_name,
				homeClanLevel: item.home_clan_level,
				enemyClanTag: item.enemy_clan_tag.replace('#', ''),
				enemyClanName: item.enemy_clan_name,
				enemyClanLevel: item.enemy_clan_level,
				warStartTime: item.war_start_time,
				warSize: item.war_size,
				warType: item.type,
			});
		});
		return wars;
	};

	// Parse Users from raw data src
	const parseUsers = () => {
		let users = [];
		getUniqueByProperties([ ...data.cwl ], [ 'tag' ]).forEach((item) => {
			users.push({
				userTag: item.tag.replace('#', ''),
				userName: item.name,
				userLevel: item.thLevel,
				userRank: item.rank,
			});
		});
		return users;
	};

	return new Promise(function(resolve) {
		setTimeout(() => {
			data.users = parseUsers();
			data.clans = parseClans();
			data.wars = parseWars();
			resolve(data);
		}, 50);
	});
};

export default { LoadData };
