import React, {createContext, useReducer} from 'react';
import PropTypes from 'prop-types';

const initialState = {
  loading: true,
  loaded: false,
  isAuthorized: false,
  wars: [],
  clans: [],
  users: [],
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    console.log(state);
    const { nState, type } = action;

    switch(type) {
      case 'UPDATE_STATE':
        const newState = {...state, ...nState};// do something with the action
        // state = {...newState};
        return newState;
      default:
        // throw new Error();
        console.log('error');
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
export { store, StateProvider }