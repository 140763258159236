import React, { useContext, useState } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { asyncComponent } from './hoc';
import DataService from './services/LoadData';
import { RouteWithLayout, LoadingScreen } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { store } from './Context';

// User containers
const asyncDashboardView = asyncComponent(() => import('./views/Dashboard'));
const asyncWarsView = asyncComponent(() => import('./views/Wars'));
const asyncWarView = asyncComponent(() => import('./views/War'));
const asyncClansView = asyncComponent(() => import('./views/Clans'));
const asyncClanView = asyncComponent(() => import('./views/Clan'));
const asyncUsersView = asyncComponent(() => import('./views/Users'));
const asyncUserView = asyncComponent(() => import('./views/User'));
const asyncSettingsView = asyncComponent(() => import('./views/Settings'));
const asyncNotFoundView = asyncComponent(() => import('./views/NotFound'));

// Authenticated Routes
const Routes = () => {
	return (
		<Switch>
			<RouteWithLayout component={asyncDashboardView} exact layout={MainLayout} path="/dashboard" />
			<RouteWithLayout component={asyncWarsView} exact layout={MainLayout} path="/wars" />
			<RouteWithLayout component={asyncWarView} layout={MainLayout} path="/war/:warID?" />
			<RouteWithLayout component={asyncClansView} exact layout={MainLayout} path="/clans" />
			<RouteWithLayout component={asyncClanView} layout={MainLayout} path="/clan/:clanTag?" />
			<RouteWithLayout component={asyncUsersView} exact layout={MainLayout} path="/users" />
			<RouteWithLayout component={asyncUserView} layout={MainLayout} path="/user/:userTag?" />
			<RouteWithLayout component={asyncSettingsView} layout={MainLayout} path="/settings" />
			<RouteWithLayout component={asyncNotFoundView} exact layout={MinimalLayout} path="/not-found" />
			<Redirect exact from="/" to="/dashboard" />
			<Redirect to="/not-found" />
		</Switch>
	);
};

// Check if the user is authenticated (check context API)
const Router = () => {
	const [ loaded, setLoaded ] = useState(false);
	const globalState = useContext(store);
	const { dispatch } = globalState;
	const routes = Routes;

	React.useEffect(() => {
		DataService.LoadData()
			.then((data) => {
				console.log('getData', data);
				setLoaded(true);
				dispatch({ nState: { ...data }, type: 'UPDATE_STATE' });
			})
			// eslint-disable-next-line no-console
			.catch((error) => {
				console.log(error);
			});
		// eslint-disable-next-line
	}, []);

	return loaded ? (
		<div className="theme-light">
			<main>
				<Switch>
					<Route component={routes} />
				</Switch>
			</main>
		</div>
	) : (
		<LoadingScreen />
	);
};

export default Router;
